import { useCallback, useContext, useEffect, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useHotkeys } from 'react-hotkeys-hook'
import { graphql, navigate, PageProps } from 'gatsby'
import styled from 'styled-components'

import { Page } from '../components/Page'
import { LevelPageHeader } from '../components/pageHeaders/LevelPageHeader'
import { backgroundGradientCSS } from '../components/BackgroundGradient'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { LevelPageTablet } from '../components/level/LevelPageTablet'
import { LevelPageDesktop } from '../components/level/LevelPageDesktop'
import { ButtonShadowIcon } from '../components/buttons/ButtonShadowIcon'
import { Cross } from '../components/icons/Cross'
import { Flex } from '../components/Flex'
import { AppContext } from '../contexts/AppContext'
import { IcebergLevels, IGatsbyImage } from '../@types/mememanifesto'

const Background = styled.div`
  ${backgroundGradientCSS}
`

const Wrapper = styled.div<{ level: IcebergLevels; visible: boolean }>`
  color: ${({ level, theme }) => {
    if (parseInt(level) <= 8) {
      return theme.color('topTextColor')
    }
    return theme.color('bottomTextColor')
  }};
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Fullscreen = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;

  .react-transform-wrapper {
    cursor: grab;

    &.grabbing {
      cursor: grabbing;
    }
  }
`

const CloseFullscreen = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`

const PageLevelInfo: React.FC<
  PageProps<
    {
      levelsYaml: {
        level: IcebergLevels
        chapterTitle: string
        info: string
        macroareas: {
          title: string
          info: string
        }[]
      }
    } & IGatsbyImage & {
        file: {
          childImageSharp: {
            fullscreenImageData: IGatsbyImageData
          }
        }
      },
    object,
    { boardVisible?: boolean }
  >
> = ({ location, data }) => {
  const { level, chapterTitle, info, macroareas } = data.levelsYaml
  const prev = (parseInt(level) - 1).toString()
  const next = (parseInt(level) + 1).toString()
  const [boardVisible, setBoardVisible] = useState(
    location.state?.boardVisible ?? false
  )
  const image = getImage(data.file)
  const fullscreenImage = data.file.childImageSharp.fullscreenImageData
  const [showMacroarea, setShowMacroarea] = useState<number | false>(false)
  const [imageLoaded, setImageloaded] = useState(false)
  const [showFullscreen, setShowFullscreen] = useState(false)

  const callParam = new URLSearchParams(location.search).get(
    'call-of-the-memesphere'
  )

  useEffect(() => {
    setBoardVisible(prevBoardVisible => prevBoardVisible || callParam !== null)
  }, [callParam])

  useHotkeys('up', () => {
    if (prev !== '0') {
      navigate(`/level-${prev}`)
    }
  })

  useHotkeys('down', () => {
    if (next !== '11') {
      navigate(`/level-${next}`)
    }
  })

  useHotkeys('esc', () => {
    setShowMacroarea(false)
  })

  useHotkeys('enter', () => {
    setBoardVisible(prevBoardVisibile => !prevBoardVisibile)
  })

  const { isTablet } = useContext(AppContext)

  // Workaround for onStartLoad on GatsbyImage throwing error
  const boardImageWrapperRef = useCallback((el: HTMLDivElement | null) => {
    if (el?.querySelector('img')?.complete) {
      setImageloaded(true)
    }
  }, [])

  return (
    <Page
      pathname={location.pathname}
      pageTitle={`Level ${level}: ${chapterTitle}`}
    >
      <Wrapper level={level} visible={!showFullscreen}>
        <LevelPageHeader level={level} />
        {isTablet && (
          <LevelPageTablet
            level={level}
            chapterTitle={chapterTitle}
            info={info}
            macroareas={macroareas}
            boardVisible={boardVisible}
            setBoardVisible={setBoardVisible}
            image={image}
            showMacroarea={showMacroarea}
            setShowMacroarea={setShowMacroarea}
            imageLoaded={imageLoaded}
            setImageloaded={setImageloaded}
            boardImageWrapperRef={boardImageWrapperRef}
            setShowFullscreen={setShowFullscreen}
          />
        )}
        {!isTablet && (
          <LevelPageDesktop
            level={level}
            chapterTitle={chapterTitle}
            info={info}
            macroareas={macroareas}
            boardVisible={boardVisible}
            setBoardVisible={setBoardVisible}
            image={image}
            showMacroarea={showMacroarea}
            setShowMacroarea={setShowMacroarea}
            imageLoaded={imageLoaded}
            setImageloaded={setImageloaded}
            boardImageWrapperRef={boardImageWrapperRef}
            setShowFullscreen={setShowFullscreen}
          />
        )}
      </Wrapper>
      <Background level={level} />
      {showFullscreen && (
        <Fullscreen>
          <TransformWrapper
            onPanningStart={ref => {
              ref.instance.wrapperComponent?.classList.add('grabbing')
            }}
            onPanningStop={ref => {
              ref.instance.wrapperComponent?.classList.remove('grabbing')
            }}
            doubleClick={{ disabled: true }}
            maxScale={13}
          >
            <TransformComponent>
              <GatsbyImage
                image={fullscreenImage}
                alt={`Board ${level}`}
                objectFit="contain"
                style={{
                  width: '100vw',
                  height: 'var(--vh)',
                }}
              />
            </TransformComponent>
          </TransformWrapper>
          <CloseFullscreen>
            <ButtonShadowIcon onClick={() => setShowFullscreen(false)}>
              <Cross />
            </ButtonShadowIcon>
          </CloseFullscreen>
        </Fullscreen>
      )}
    </Page>
  )
}

export default PageLevelInfo

export const query = graphql`
  query ($id: String!, $level: String!) {
    levelsYaml(id: { eq: $id }) {
      level
      chapterTitle
      info
      macroareas {
        title
        info
      }
    }
    file(
      sourceInstanceName: { eq: "boards" }
      relativePath: { glob: "full/*" }
      name: { eq: $level }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
        fullscreenImageData: gatsbyImageData(
          placeholder: NONE
          layout: FULL_WIDTH
          breakpoints: [7000]
        )
      }
      name
    }
  }
`
