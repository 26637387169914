import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas10: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4', '5']}
    >
      <path
        data-macroarea="1"
        d="M59.5 87H1V1H87.5V58V59H88.5H146V88V203H60.5V88V87H59.5Z"
      />
      <path
        data-macroarea="2"
        d="M148 87V58V57H147H89.5V1H351V114.5H264.5V88V87H263.5H148Z"
      />
      <path
        data-macroarea="3"
        d="M262.5 115.5V116.5H263.5H351V146H291H290V147V203H148V89H262.5V115.5Z"
      />
      <path
        data-macroarea="4"
        d="M319.5 261H292V148H351V290H320.5V262V261H319.5Z"
      />
      <path
        data-macroarea="5"
        d="M58.5 204V205H59.5H290V262V263H291H318.5V291V292H319.5H351V494.5H1V89H58.5V204Z"
      />
    </MacroareasSVG>
  )
}
