import styled from 'styled-components'

import { IcebergLevels } from '../../@types/mememanifesto'
import { DetectiveNavigationDots } from '../DetectiveNavigationDots'
import { Iceberg } from '../icons/Iceberg'
import { Detective } from '../icons/Detective'
import { PageHeader } from './PageHeader'
import { ButtonShadowIconText } from '../buttons/ButtonShadowIconText'
import { navigate } from 'gatsby'
import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'
import { hideOnDesktop, hideOnTablet } from '../../theme/theme'

const LevelButtonMobile = styled(ButtonShadowIcon)`
  ${hideOnDesktop}
`

const LevelButtonDesktop = styled(ButtonShadowIconText)`
  ${hideOnTablet}
`

const WallButtonMobile = styled(ButtonShadowIcon)`
  ${hideOnDesktop}
`

const WallButtonDesktop = styled(ButtonShadowIconText)`
  ${hideOnTablet}
`

export const LevelPageHeader: React.FC<{
  level: IcebergLevels
}> = ({ level }) => {
  return (
    <PageHeader>
      <LevelButtonMobile
        onClick={() => navigate(`/#!level-${level}`, { state: { level } })}
      >
        <Iceberg />
      </LevelButtonMobile>
      <LevelButtonDesktop
        side="left"
        text="Go To The Iceberg"
        icon={<Iceberg />}
        link={`/#!level-${level}`}
        state={{ level }}
      />
      <DetectiveNavigationDots level={level} />
      <WallButtonMobile
        onClick={() => navigate(`/detective-wall`, { state: { level } })}
      >
        <Detective />
      </WallButtonMobile>
      <WallButtonDesktop
        side="right"
        text="Go To The Detective Wall"
        icon={<Detective />}
        link="/detective-wall"
      />
    </PageHeader>
  )
}
