import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas7: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3']}
    >
      <path
        d="M33.0969 431.669V496.5H1V1.5H234.67V29.1441V30.1441H235.67H262.749V149.253H235.67H234.67V150.253V175.385H178.006H177.006V176.385V262.825H148.419V223.12V222.12H147.419H106.302H105.302V223.12V324.133H60.1709H59.1709V325.133V430.669H34.0969H33.0969V431.669Z"
        data-macroarea="1"
      />
      <path
        d="M206.085 293.479H148.419V264.825H178.006H179.006V263.825V177.385H235.67H236.67V176.385V151.253H262.749V263.825V264.825H263.749H295.339H296.339V263.825V207.034H351V496.5H207.085V294.479V293.479H206.085Z"
        data-macroarea="2"
      />
      <path
        d="M294.339 206.034V262.825H264.749V150.253V29.1441V28.1441H263.749H236.67V1.5H351V205.034H295.339H294.339V206.034ZM106.302 326.133H107.302V325.133V224.12H146.419V263.825V294.479V295.479H147.419H205.086V496.5H35.0969V432.669H60.171H61.171V431.669V326.133H106.302Z"
        data-macroarea="3"
      />
    </MacroareasSVG>
  )
}
