import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas4: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4', '5']}
    >
      <rect data-macroarea="1" x="203" y="325" width="147" height="171" />
      <rect data-macroarea="2" x="1" y="325" width="200" height="171" />
      <rect data-macroarea="3" x="1" y="1" width="349" height="83" />
      <rect data-macroarea="4" x="1" y="86" width="349" height="86" />
      <rect data-macroarea="5" x="1" y="174" width="349" height="149" />
    </MacroareasSVG>
  )
}
