import styled, { useTheme } from 'styled-components'
import { IcebergLevels } from '../@types/mememanifesto'
import { Flex } from './Flex'
import { DetectiveFilledHat } from './icons/DetectiveFilledHat'

interface Props {
  level: IcebergLevels
}

const Circle = styled.div<{ color: string }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-top: 17px;
  margin-right: 1px;
`

const DetectiveWrapper = styled.span`
  position: relative;
  line-height: 0;
`

const DetectiveS = styled(DetectiveFilledHat)<{ level: IcebergLevels }>`
  > * {
    fill: ${({ level }) =>
      ['1', '2', '3', '4', '5', '6', '7', '8'].includes(level)
        ? '#000'
        : '#fff'};
  }
`

export const DetectiveNavigationDots: React.FC<Props> = ({ level }) => {
  const theme = useTheme()
  const detective = (
    <DetectiveWrapper>
      <DetectiveS size="38px" level={level} />
    </DetectiveWrapper>
  )
  return (
    <Flex align="center" justify="center">
      {level === '1' ? detective : <Circle color={theme.colors.level1} />}
      {level === '2' ? detective : <Circle color={theme.colors.level2} />}
      {level === '3' ? detective : <Circle color={theme.colors.level3} />}
      {level === '4' ? detective : <Circle color={theme.colors.level4} />}
      {level === '5' ? detective : <Circle color={theme.colors.level5} />}
      {level === '6' ? detective : <Circle color={theme.colors.level6} />}
      {level === '7' ? detective : <Circle color={theme.colors.level7} />}
      {level === '8' ? detective : <Circle color={theme.colors.level8} />}
      {level === '9' ? detective : <Circle color={theme.colors.level9} />}
      {level === '10' ? detective : <Circle color={theme.colors.level10} />}
    </Flex>
  )
}
