import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas5: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG setShowMacroarea={setShowMacroarea} macroareas={['1', '2']}>
      <rect data-macroarea="1" x="1" y="249.5" width="349" height="246.5" />
      <rect data-macroarea="2" x="1" y="1" width="349" height="246.5" />
    </MacroareasSVG>
  )
}
