import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas6: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4']}
    >
      <path data-macroarea="1" d="M1 1.5H175V231.635H1V1.5Z" />
      <path data-macroarea="2" d="M177 232.635V1.5H351V262.351H177V232.635Z" />
      <path data-macroarea="3" d="M176 263.351H352V497.5H204.568V321.259H176" />
      <path
        data-macroarea="4"
        d="M176 322.259H203.568V496.5H1V233.635H175V321.259V322.259H176Z"
      />
    </MacroareasSVG>
  )
}
