import { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import domAlign from 'dom-align'

import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'
import { Lens } from '../icons/Lens'
import { useWindowSize } from '../../hooks/useWindowSize'

const MacroareaSVGStyled = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 352 497',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  margin: 0 auto;
  height: 100%;
`

const LensesWrapper = styled.div`
  position: absolute;
  pointer-events: none;
`

export const MacroareasSVG: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
  macroareas: string[]
  children: Iterable<React.ReactNode>
}> = ({ setShowMacroarea, macroareas, children }) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const lensesRef = useRef<HTMLDivElement[]>([])

  const { width, height } = useWindowSize()

  useEffect(() => {
    const svg = svgRef.current
    const click = (event: Event) => {
      if (event.target) {
        setShowMacroarea(prev => {
          const element = event.target as SVGElement | null
          if (element?.dataset.macroarea) {
            const num = parseInt(element.dataset.macroarea) - 1
            return num === prev ? false : num
          }
          return prev
        })
      }
    }
    svg?.addEventListener('click', click)

    return () => {
      svg?.removeEventListener('click', click)
    }
  }, [setShowMacroarea])

  const lensesCallbackRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      lensesRef.current.push(el)
    }
  }, [])

  useEffect(() => {
    lensesRef.current.forEach(div => {
      if (svgRef.current) {
        const areaEl = svgRef.current.querySelector<SVGElement>(
          `[data-macroarea="${div.dataset.macroarea}"]`
        )
        domAlign(div, areaEl, { points: ['cc', 'cc'] })
      }
    })
  }, [width, height])

  return (
    <>
      <MacroareaSVGStyled ref={svgRef} fill="transparent">
        {children}
      </MacroareaSVGStyled>
      {macroareas.map(macroarea => (
        <LensesWrapper
          key={macroarea}
          ref={lensesCallbackRef}
          data-macroarea={macroarea}
        >
          <ButtonShadowIcon shadow="intense">
            <Lens />
          </ButtonShadowIcon>
        </LensesWrapper>
      ))}
    </>
  )
}
