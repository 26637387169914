import ReactMarkdown from 'react-markdown'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Flex } from '../Flex'
import { Arrow } from '../icons/Arrow'
import { Lens } from '../icons/Lens'
import { Menu } from '../icons/Menu'
import { fontSneaky } from '../../theme/theme'
import { MacroareaBox } from '../macroareas/MacroareaBox'
import { Macroareas } from '../macroareas/Macroareas'
import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'
import { LevelPageMacroareaPills } from './LevelPageMacroareaPills'
import { IcebergLevels } from '../../@types/mememanifesto'
import { Fullscreen } from '../icons/Fullscreen'

const Content = styled(Flex)`
  overflow: hidden;
  flex: 1;
`

const Article = styled.article<{ boardVisible: boolean }>`
  overflow: ${props => (props.boardVisible ? 'visible' : 'auto')};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding: 0 30px;
`

const Title = styled.h1`
  font-size: clamp(30px, 6vw, 80px);
  margin-bottom: 0;
  text-transform: uppercase;
  margin-right: 10px;
  flex: 1;
`

const ArticleContent = styled.div`
  ${fontSneaky}
`

const BoardWrapper = styled.aside`
  flex: 1 0 0;
  min-height: 0;
  display: flex;
  height: calc(var(--vh) - 280px);
  justify-content: center;
`

const BoardImageWrapper = styled.div`
  position: relative;
  height: 100%;
`

const ShowBoardButtonWrapper = styled(Flex)`
  margin-bottom: 10px;
  top: 0;
`

const NavButton = styled(ButtonShadowIcon)`
  flex: 1;
`

export const LevelPageTablet: React.FC<{
  level: IcebergLevels
  chapterTitle: string
  info: string
  macroareas: {
    title: string
    info: string
  }[]
  boardVisible: boolean
  setBoardVisible: (boardVisible: boolean) => void
  image: IGatsbyImageData | undefined
  showMacroarea: number | false
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
  imageLoaded: boolean
  setImageloaded: (loaded: boolean) => void
  boardImageWrapperRef: (el: HTMLDivElement | null) => void
  setShowFullscreen: (show: boolean) => void
}> = ({
  level,
  chapterTitle,
  info,
  macroareas,
  boardVisible,
  setBoardVisible,
  image,
  showMacroarea,
  setShowMacroarea,
  imageLoaded,
  setImageloaded,
  boardImageWrapperRef,
  setShowFullscreen,
}) => {
  const prev = (parseInt(level) - 1).toString()
  const next = (parseInt(level) + 1).toString()

  return (
    <Content column>
      <Article boardVisible={boardVisible}>
        <Flex align="end" style={{ marginBottom: 30 }}>
          <Title>{chapterTitle}</Title>
          <ShowBoardButtonWrapper align="end" column>
            <ButtonShadowIcon onClick={() => setBoardVisible(!boardVisible)}>
              {boardVisible && <Menu />}
              {!boardVisible && <Lens />}
            </ButtonShadowIcon>
            <ButtonShadowIcon
              onClick={() => setShowFullscreen(true)}
              style={{
                marginTop: 10,
                visibility: boardVisible ? 'visible' : 'hidden',
              }}
            >
              <Fullscreen />
            </ButtonShadowIcon>
          </ShowBoardButtonWrapper>
        </Flex>
        {boardVisible && (
          <BoardWrapper>
            <BoardImageWrapper ref={boardImageWrapperRef}>
              {image && (
                <GatsbyImage
                  image={image}
                  alt={`Board ${level}`}
                  objectFit="contain"
                  style={{ height: '100%' }}
                  onLoad={() => setImageloaded(true)}
                />
              )}
              {imageLoaded && (
                <Macroareas level={level} setShowMacroarea={setShowMacroarea} />
              )}
              {showMacroarea !== false && (
                <MacroareaBox
                  macroarea={macroareas[showMacroarea]}
                  setShowMacroarea={setShowMacroarea}
                />
              )}
            </BoardImageWrapper>
          </BoardWrapper>
        )}
        {!boardVisible && (
          <ArticleContent>
            <ReactMarkdown>{info}</ReactMarkdown>
          </ArticleContent>
        )}
        {!boardVisible && (
          <footer>
            <LevelPageMacroareaPills
              macroareas={macroareas}
              setBoardVisible={setBoardVisible}
              setShowMacroarea={setShowMacroarea}
            />
            <Flex style={{ marginTop: 60 }}>
              {prev !== '0' && (
                <NavButton
                  style={{ marginRight: 5 }}
                  onClick={() => navigate(`/level-${prev}`)}
                >
                  <Arrow direction="up" />
                </NavButton>
              )}
              {next !== '11' && (
                <NavButton
                  style={{ marginLeft: 5 }}
                  onClick={() => navigate(`/level-${next}`)}
                >
                  <Arrow direction="down" />
                </NavButton>
              )}
            </Flex>
          </footer>
        )}
      </Article>
    </Content>
  )
}
