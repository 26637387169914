import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas9: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3']}
    >
      <path
        data-macroarea="1"
        d="M30.5448 233.235V261.264H1V1H351V174.677H204.29H203.29V175.677V232.235H31.5448H30.5448V233.235Z"
      />
      <path
        data-macroarea="2"
        d="M204.29 234.235H205.29V233.235V176.677H351V348.852H1V263.264H31.5448H32.5448V262.264V234.235H204.29Z"
      />
      <path data-macroarea="3" d="M1 350.852H351V496H1V350.852Z" />
    </MacroareasSVG>
  )
}
