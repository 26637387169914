import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas1: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4', '5']}
    >
      <path data-macroarea="1" d="M263.5 0L0 0.5V113.5L206 113V53H263.5V0Z" />
      <path
        data-macroarea="2"
        d="M263.5 0L351.5 1V320H292.5V233.5H263.5V204H233V143.5H206V53H263.5V0Z"
      />
      <path
        data-macroarea="3"
        d="M206 113H0V351H57V380H178.5V407.5H232.5V320H292.5V233.5H263.5V204H233V143.5H206.5L206 113Z"
      />
      <path
        data-macroarea="4"
        d="M57 351H0V497H117.5V468.5H178.5V380H57V351Z"
      />
      <path
        data-macroarea="5"
        d="M117.5 468.5V497H350V320H232.5V407.5H178.5V468.5H117.5Z"
      />
    </MacroareasSVG>
  )
}
