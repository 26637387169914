import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Flex } from '../Flex'
import { Arrow } from '../icons/Arrow'
import { Lens } from '../icons/Lens'
import { Cross } from '../icons/Cross'
import { fontSneaky, noScrollbars } from '../../theme/theme'
import { MacroareaBox } from '../macroareas/MacroareaBox'
import { Macroareas } from '../macroareas/Macroareas'
import { ButtonShadowIconText } from '../buttons/ButtonShadowIconText'
import { LevelPageMacroareaPills } from './LevelPageMacroareaPills'
import { IcebergLevels } from '../../@types/mememanifesto'
import { Fullscreen } from '../icons/Fullscreen'
import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'

const Content = styled(Flex)`
  overflow: hidden;
  flex: 1;
`

const LeftColumn = styled.nav`
  flex: 0 1 360px;
  min-width: 270px;
  align-self: end;
  padding-left: 70px;
  padding-bottom: 70px;

  > * + * {
    margin-top: 20px;
  }
`

const Article = styled.article`
  ${noScrollbars}
  max-width: 850px;
  overflow: auto;
  flex: 1 0 50%;
  padding-right: 8px;
`

const Title = styled.h1`
  font-size: clamp(40px, 6vw, 80px);
  margin-bottom: 0;
  text-transform: uppercase;
`

const ArticleContent = styled.div`
  ${fontSneaky}
`

const BoardWrapper = styled.aside`
  flex: 1 0 50%;
  display: flex;
  padding-left: calc(4vw - 8px);
`

const BoardImageWrapper = styled.div`
  width: 50%;
  align-self: start;
  position: relative;
`

const ShowBoardButtonWrapper = styled(Flex)<{ boardVisible: boolean }>`
  margin-bottom: 10px;
  position: ${({ boardVisible }) => (boardVisible ? 'sticky' : 'static')};
  top: 0;
`

export const LevelPageDesktop: React.FC<{
  level: IcebergLevels
  chapterTitle: string
  info: string
  macroareas: {
    title: string
    info: string
  }[]
  boardVisible: boolean
  setBoardVisible: (boardVisible: boolean) => void
  image: IGatsbyImageData | undefined
  showMacroarea: number | false
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
  imageLoaded: boolean
  setImageloaded: (loaded: boolean) => void
  boardImageWrapperRef: (el: HTMLDivElement | null) => void
  setShowFullscreen: (show: boolean) => void
}> = ({
  level,
  chapterTitle,
  info,
  macroareas,
  boardVisible,
  setBoardVisible,
  image,
  showMacroarea,
  setShowMacroarea,
  imageLoaded,
  setImageloaded,
  boardImageWrapperRef,
  setShowFullscreen,
}) => {
  const prev = (parseInt(level) - 1).toString()
  const next = (parseInt(level) + 1).toString()
  const [boardHoverTextVisible, setBoardHoverTextVisible] = useState(false)

  return (
    <Content>
      <LeftColumn>
        {prev !== '0' && (
          <ButtonShadowIconText
            side="left"
            link={`/level-${prev}`}
            state={{ boardVisible }}
            text="Up one Level"
            icon={<Arrow direction="up" />}
            variant="tall"
          />
        )}
        <ButtonShadowIconText
          side="left"
          link={`/level-${next}`}
          state={{ boardVisible }}
          text="Down one Level"
          icon={<Arrow direction="down" />}
          variant="tall"
          style={{ visibility: next !== '11' ? 'visible' : 'hidden' }}
        />
      </LeftColumn>
      <Article>
        <Title>{chapterTitle}</Title>
        <ShowBoardButtonWrapper align="end" column boardVisible={boardVisible}>
          <ButtonShadowIconText
            side="right"
            text={
              boardVisible || !boardHoverTextVisible
                ? ''
                : `Watch the board of Level ${level}`
            }
            icon={boardVisible ? <Cross /> : <Lens />}
            onClick={() => setBoardVisible(!boardVisible)}
            onMouseEnter={() => setBoardHoverTextVisible(true)}
            onMouseLeave={() => setBoardHoverTextVisible(false)}
          />
          <ButtonShadowIcon
            onClick={() => setShowFullscreen(true)}
            style={{
              marginTop: 10,
              visibility: boardVisible ? 'visible' : 'hidden',
            }}
          >
            <Fullscreen />
          </ButtonShadowIcon>
        </ShowBoardButtonWrapper>
        <ArticleContent>
          <ReactMarkdown>{info}</ReactMarkdown>
        </ArticleContent>
        <footer>
          <LevelPageMacroareaPills
            macroareas={macroareas}
            setBoardVisible={setBoardVisible}
            setShowMacroarea={setShowMacroarea}
          />
        </footer>
      </Article>
      <BoardWrapper>
        {boardVisible && (
          <BoardImageWrapper ref={boardImageWrapperRef}>
            {image && (
              <GatsbyImage
                image={image}
                alt={`Board ${level}`}
                objectFit="contain"
                objectPosition={0}
                onLoad={() => setImageloaded(true)}
              />
            )}
            {imageLoaded && (
              <Macroareas level={level} setShowMacroarea={setShowMacroarea} />
            )}
            {showMacroarea !== false && (
              <MacroareaBox
                macroarea={macroareas[showMacroarea]}
                setShowMacroarea={setShowMacroarea}
              />
            )}
          </BoardImageWrapper>
        )}
      </BoardWrapper>
    </Content>
  )
}
