import styled from 'styled-components'
import { fontHaasGrot } from '../../theme/theme'
import { ButtonShadow } from '../buttons/ButtonShadow'

const MacroareasTitle = styled.h2`
  ${fontHaasGrot}
  font-size: 15px;
  text-indent: 20px;
  margin-bottom: 1em;
`

const MacroareaPill = styled.div`
  margin-bottom: 0.25em;
`

export const LevelPageMacroareaPills: React.FC<{
  macroareas: {
    title: string
    info: string
  }[]
  setBoardVisible: (boardVisible: boolean) => void
  setShowMacroarea: (macroarea: number | false) => void
}> = ({ macroareas, setBoardVisible, setShowMacroarea }) => {
  return (
    <>
      <MacroareasTitle>Discover more about this chapter:</MacroareasTitle>
      {macroareas.map(({ title }, index) => (
        <MacroareaPill key={title}>
          <ButtonShadow
            onClick={() => {
              setBoardVisible(true)
              setShowMacroarea(index)
            }}
          >
            {title}
          </ButtonShadow>
        </MacroareaPill>
      ))}
    </>
  )
}
