import { IcebergLevels } from '../../@types/mememanifesto'
import { Macroareas1 } from './Macroareas1'
import { Macroareas2 } from './Macroareas2'
import { Macroareas3 } from './Macroareas3'
import { Macroareas4 } from './Macroareas4'
import { Macroareas5 } from './Macroareas5'
import { Macroareas6 } from './Macroareas6'
import { Macroareas7 } from './Macroareas7'
import { Macroareas8 } from './Macroareas8'
import { Macroareas9 } from './Macroareas9'
import { Macroareas10 } from './Macroareas10'

export const Macroareas: React.FC<{
  level: IcebergLevels
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ level, setShowMacroarea }) => {
  const components = {
    '1': Macroareas1,
    '2': Macroareas2,
    '3': Macroareas3,
    '4': Macroareas4,
    '5': Macroareas5,
    '6': Macroareas6,
    '7': Macroareas7,
    '8': Macroareas8,
    '9': Macroareas9,
    '10': Macroareas10,
  }
  const Component = components[level]
  if (Component) {
    return <Component setShowMacroarea={setShowMacroarea} />
  }
  return null
}
