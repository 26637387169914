import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas3: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4']}
    >
      <path
        data-macroarea="1"
        d="M234.131 409.825H350.428V496.5H0.5V205.427H30.003H30.503V204.927V88.1748H88.9376H89.4376V87.6748V0.5H175.768V115.167H147.336H146.836V115.667V146.829V147.329H147.336H175.768V322.707V323.207H176.268H233.631V350.727H205.735H205.235V351.227V380.804V381.304H205.735H233.631V409.325V409.825H234.131Z"
      />
      <path
        data-macroarea="2"
        d="M30.0388 87.6748V204.427H0.5V0.5H88.4376V87.1748H30.5388H30.0388V87.6748Z"
      />
      <path
        data-macroarea="3"
        d="M177.304 115.667V0.5H350.964V262.524H293.565V234.504V234.004H293.065H262.527H262.027V234.504V262.524H234.095V234.504V234.004H233.595H177.304V146.829V146.329H176.804H148.372V116.167H176.804H177.304V115.667Z"
      />
      <path
        data-macroarea="4"
        d="M234.494 380.5L205.699 380.827V351.727H234H234.5V351.227V322.5V322H234H177.005L177.835 235.004H233.095V262.496V262.996H233.595H262.527H263.027V262.496V235.004H293.101V262.496V262.996H293.601H350.5V408.5H235V381V380.494L234.494 380.5Z"
      />
    </MacroareasSVG>
  )
}
