import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas2: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG
      setShowMacroarea={setShowMacroarea}
      macroareas={['1', '2', '3', '4', '5']}
    >
      <path
        data-macroarea="1"
        d="M22.3017 32.9244L140.488 32.9559L140.762 154.109L84.4805 154.408L84.3942 182.088L22.3717 182.102"
      />
      <path
        data-macroarea="2"
        d="M140.488 32.9559L374.195 33.2241L374.259 181.837L315.073 182.319L314.987 92.4472L196.384 91.9971L196.514 213.756L255.601 213.437L255.478 272.43L374.238 272.279L374.262 331.512L53.5765 332.504L53.2967 272.628L22.5862 272.809L22.3717 182.102L84.3942 182.088L84.4805 154.408L140.762 154.109L140.488 32.9559Z"
      />
      <path
        data-macroarea="3"
        d="M196.384 91.9971L314.987 92.4472L315.073 182.319L374.259 181.837L374.238 272.279L255.478 272.43L255.601 213.437L196.514 213.756L196.384 91.9971Z"
      />
      <path
        data-macroarea="4"
        d="M22.5862 272.809L53.2967 272.628L53.7211 390.637L255.9 390.401L255.744 539.846L21.8761 540L22.5862 272.809Z"
      />
      <path
        data-macroarea="5"
        d="M53.5125 332.65L374.262 331.512L374.369 539.212L255.744 539.846L255.9 390.401L53.7211 390.637L53.5125 332.65Z"
      />
    </MacroareasSVG>
  )
}
