import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Cross } from '../icons/Cross'
import { fontHaasGrot } from '../../theme/theme'
import { Button } from '../buttons/Button'
import { Flex } from '../Flex'

const Wrapper = styled(Flex)`
  ${fontHaasGrot}
  position: absolute;
  top: 0;
  bottom: 0;
  color: ${({ theme }) => theme.color('topTextColor')};
  background-color: ${({ theme }) => theme.color('backgroundColor')};
  box-sizing: border-box;
  padding: 25px;
  font-size: 18px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 21px;
  max-height: 560px;
  max-width: 420px;
  left: 0;
  right: 0;
  margin: auto;

  ${props => props.theme.mediaQuery('desktop')} {
    max-width: none;
    min-width: 360px;
    left: unset;
    right: calc(100% + 10px);
    bottom: initial;
  }
`

const MacroareaTitle = styled.h3`
  padding-right: 35px;
  font: inherit;
  font-weight: bold;
`

const ButtonCloseMacroarea = styled(Button)`
  position: absolute;
  right: 25px;
`

const TextWrap = styled.div`
  overflow-y: auto;
`

interface Props {
  macroarea: { title: string; info: string }
  setShowMacroarea: (macroarea: false) => void
}

export const MacroareaBox: React.FC<Props> = ({
  macroarea,
  setShowMacroarea,
}) => {
  return (
    <Wrapper column>
      <ButtonCloseMacroarea onClick={() => setShowMacroarea(false)}>
        <Cross />
      </ButtonCloseMacroarea>
      <MacroareaTitle>{macroarea.title}</MacroareaTitle>
      <TextWrap>
        <ReactMarkdown>{macroarea.info}</ReactMarkdown>
      </TextWrap>
    </Wrapper>
  )
}
