import { MacroareasSVG } from './MacroareasSVG'

export const Macroareas8: React.FC<{
  setShowMacroarea: React.Dispatch<React.SetStateAction<number | false>>
}> = ({ setShowMacroarea }) => {
  return (
    <MacroareasSVG setShowMacroarea={setShowMacroarea} macroareas={['1', '2']}>
      <path
        data-macroarea="1"
        d="M263.874 206.206H264.874V205.206V1H351.5V496H1.5V263.764H174.749V292.795V293.795H175.749H234.833H235.833V292.795V206.206H263.874Z"
      />
      <path
        data-macroarea="2"
        d="M175.749 261.764H1.5V1H262.874V204.206H234.833H233.833V205.206V291.795H176.749V262.764V261.764H175.749Z"
      />
    </MacroareasSVG>
  )
}
